import api from "@/services/apiService"

class SetsService {
  saveSetsResults(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'round-sets/' + roundId + '/result', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateProcessingTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/processingTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateProcessPrize(roundId, betCode) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/process-prize/' + betCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateLoseItems(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/loseItems')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateWonTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/wonTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateLoseTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/loseTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  closeTickets(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'round-sets/' + roundId + '/closeTicket/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  changeResultItem(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET+'round-sets/'+roundId+'/resetItem/'+uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new SetsService()
