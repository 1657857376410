<template>
  <div class="card-header">
    <h5 class="card-title mb-0">
      <img :src="marketIcon" style="height: 25px;" class="mr-2">
      <span class="text-primary mr-2">[{{groupName}}] {{marketTitle}}</span>
      <span class="text-dark mr-2">วันที่</span>
      <span class="text-primary mr-2">{{ marketDate }}</span>
      <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
    </h5>
  </div>
</template>
<script>
export default {
  name: 'RoundCardHeader',
  computed: {
    globalMarket() {
      return this.$store.state.globalMarket
    },
    groupName() {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === this.globalMarket.groupId
      })

      if(group) {
        return group?.groupTitle
      }else{
        return ''
      }
    },
    marketTitle() {
      const market = this.$store.state.markets.find((m)=>{
        return m._id === this.globalMarket.marketId
      })

      return market?.marketTitle || this.data?.marketId
    },
    marketIcon() {
      const market = this.$store.state.markets.find((m)=>{
        return m._id === this.globalMarket.marketId
      })

      return market?.imageIcon || null
    },
    marketDate() {
      return this.globalMarket.round?.roundDate?.date
    }
  },
}
</script>
