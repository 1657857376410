<template>
  <div>
    <div class="card-body">

      <div v-if="!isAvailableStatus" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle"></i> ไม่สามารถออกผลได้ ({{roundStatus}})
      </div>
      <div v-else-if="!isRoundClosed" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        <RoundStatus v-if="round" :data="round" @cssStyle="setStatusStyle" class="badge badge-status font-weight-normal" :class="[cssStatusClass.bg, cssStatusClass.text]" style="font-size: 100%;" />
        ยังไม่ถึงเวลาออกผล
      </div>

      <div v-else>
        <div v-if="isPaid" class="alert alert-success mb-4">
          <i class="far fa-check-circle"></i> จ่ายเงินแล้ว
        </div>

        <div v-if="!isPaid" class="row mb-4">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-primary font-weight-bold mb-0">ผลรางวัล</label>
          </div>
          <div class="col-sm-9 form-inline">
            <span v-if="isPaid" class="badge badge-success mr-1">{{inputResult}}</span>
            <b-form-input
              v-else
              class="text-primary"
              :placeholder="resultTextHolder"
              v-model="inputResult"
              v-on:keydown="keypress"
              :disabled="!(resultStep==='waitResult' || resultStep==='Input-Result' || !resultStep)"
              :maxlength="resultLength">
            </b-form-input>
          </div>
        </div>

        <div v-for="openBet in marketOpenBets" class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">{{ openBet.text }}</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-if="!results[openBet.code].length" class="text-secondary">-</span>
            <span v-else v-for="number in results[openBet.code]" class="badge badge-success mr-1 mb-1">{{ number }}</span>
          </div>
        </div>

      </div>
    </div>
    <div v-if="isAvailableStatus && isRoundClosed && !isPaid" class="card-footer text-right">
      <b-button
        :disabled="!(resultStep==='waitResult' || resultStep==='Input-Result' || !resultStep)"
        variant="primary"
        @click="saveResults"
      ><i class="far fa-save"></i> บันทึกผล</b-button>
    </div>

    <div v-if="isAvailableStatus && resultStep" class="card-footer p-3">
      <div class="px-1 mb-2">
        <b-button
          :variant="resultStep==='Input-Result'?'info':'outline-info'"
          :disabled="resultStep!='Input-Result'"
          size="sm"
          @click="ProcessingTickets"
        >เริ่มคำนวนผล</b-button>
      </div>
      <div class="mb-1">
        <b-button
          v-for="step in processPrizes" class="m-1"
          :key="step.code"
          :variant="step.isAvailable?'info':'outline-info'"
          :disabled="!step.isAvailable"
          size="sm"
          @click="ProcessPrize(step)"
        >ถูกรางวัล {{ step.text }}</b-button>

        <b-button
          :variant="resultStep===lastProcessPrize?'info':'outline-info'"
          :disabled="resultStep!==lastProcessPrize"
          size="sm"
          @click="LoseItems()"
        >รายการไม่ถูกรางวัล</b-button>
      </div>
      <div class="mb-1">
        <b-button
          class="m-1"
          :variant="resultStep==='loseItems'?'info':'outline-info'"
          :disabled="resultStep!='loseItems'"
          size="sm"
          @click="WonTickets()"
        >โพยถูกรางวัล</b-button>
        <b-button
          class="m-1"
          :variant="resultStep==='WonTickets'?'info':'outline-info'"
          :disabled="resultStep!='WonTickets'"
          size="sm"
          @click="LoseTickets()"
        >โพยไม่ถูกรางวัล</b-button>
        <b-button
          class="m-1"
          :variant="resultStep==='LoseTickets'?'info':'outline-info'"
          :disabled="resultStep!='LoseTickets'"
          size="sm"
          @click="payWonMembers()"
        >จ่ายเงินสมาชิก</b-button>
        <b-button
          class="m-1"
          :variant="resultStep==='payWonMember'?'info':'outline-info'"
          :disabled="resultStep!='payWonMember'"
          size="sm"
          @click="findAgents()"
        >สรุปยอดเอเย่นต์</b-button>
        <b-button
          class="m-1"
          :variant="resultStep==='findAgent'?'info':'outline-info'"
          :disabled="resultStep!='findAgent'"
          size="sm"
          @click="payAgents()"
        >ปรับยอดเอเย่นต์</b-button>
      </div>
      <div class="mb-1">
        <b-button
          class="m-1"
          :variant="resultStep==='payAgent'?'info':'outline-info'"
          :disabled="resultStep!='payAgent'"
          size="sm"
          @click="closeTickets()"
        >จบการออกผล</b-button>
        <b-button
          class="m-1"
          :variant="resultStep==='closeTicket'?'info':'outline-info'"
          :disabled="resultStep!='closeTicket'"
          size="sm"
          @click="closeRound()"
        >ปิดงวดหวย</b-button>
      </div>
    </div>

    <div v-if="isAvailableStatus && resultStep" class="card-footer p-3">
      <button
        class="btn btn-danger btn-sm m-1"
        @click="changeResult('cancelResult', 'ยกเลิกผล/แก้ไขผลใหม่')"
        :disabled="!canChangeResult"
        :class="canChangeResult?'btn-danger':'btn-outline-danger'"
      >
        <i class="fas fa-undo-alt"></i> แก้ไขผล/คำนวนใหม่
      </button>

      <button
        class="btn btn-danger btn-sm m-1"
        @click="changeResult('rollbackCredit', 'ดึงเครดิตกลับจากสมาชิก')"
        :disabled="resultStep!=='cancelResult'"
        :class="resultStep==='cancelResult'?'btn-danger':'btn-outline-danger'"
      >
        ดึงเครดิตกลับ
      </button>

      <button
        class="btn btn-danger btn-sm m-1"
        @click="changeResultTicket('ปรับสถานะโพย')"
        :disabled="resultStep!=='rollbackCredit'"
        :class="resultStep==='rollbackCredit'?'btn-danger':'btn-outline-danger'"
      >
        รีเซ็ตโพย
      </button>

      <button
        class="btn btn-danger btn-sm m-1"
        @click="changeResultItem('รีเซ็ตรายการแทง')"
        :disabled="resultStep!=='resetTicket'"
        :class="resultStep==='resetTicket'?'btn-danger':'btn-outline-danger'"
      >
        รีเซ็ตรายการแทง
      </button>


      <button
        class="btn btn-danger btn-sm m-1"
        @click="changeResult('resetRound', 'ปรับสถานะงวดหวย')"
        :disabled="resultStep!=='resetItem'"
        :class="resultStep==='resetItem'?'btn-danger':'btn-outline-danger'"
      >
        รีเซ็ตงวดหวย
      </button>
    </div>
  </div>
</template>
<script>
import { marketTypes } from "@/configs/market.config"
import LottoService from '@/services/lottoService'
import SetsService from '@/services/setsService'
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import RoundStatus from './RoundStatus'
import _ from 'lodash'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'ResultLottoset',
  props: ['market', 'round'],
  components: {
    RoundStatus
  },
  data() {
    return {
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      },
      inputResult: '',
      uniqueCode: cryptoRandomString({length: 12, type: 'alphanumeric'})
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    roundStatus() {
      return this.round?.status
    },
    isAvailableStatus() {
      if(!this.round)
        return false

      return ['Open', 'Close', 'Complete', 'Paid'].includes(this.round.status)
    },
    isRoundClosed() {
      if(!this.round)
        return false

      if(moment().isBefore(this.round.roundDate.close))
        return false

      return true
    },
    isPaid() {
      return this.round?.status === 'Paid'
    },
    resultStep() {
      return this.round?.resultStep
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.market?.openBets?.[o.code]
      })
    },
    bigestPrize() {
      return this.marketOpenBets?.[0]?.code ?? null
    },
    resultText() {
      return this.round?.results?.[this.bigestPrize]?.[0] ?? ''
    },
    resultLength() {
      return {
        'fiveNumber': 5,
        'fourNumber': 4
      }[this.bigestPrize] ?? 0
    },
    resultTextHolder() {
      return {
        'fiveNumber': 'ผล 5 ตัว',
        'fourNumber': 'ผล 4 ตัว'
      }[this.bigestPrize] ?? 'ผลรางวัล'
    },
    fiveNumber() {
      if(!this.inputResult)
        return []

      const awards = this.inputResult.split('')
      if(awards.length >= 5) {
        return [`${awards[awards.length-5]}${awards[awards.length-4]}${awards[awards.length-3]}${awards[awards.length-2]}${awards[awards.length-1]}`]
      }else{
        return []
      }
    },
    fourNumber() {
      if(!this.inputResult)
        return []

      const awards = this.inputResult.split('')
      if(awards.length >= 4) {
        return [`${awards[awards.length-4]}${awards[awards.length-3]}${awards[awards.length-2]}${awards[awards.length-1]}`]
      }else{
        return []
      }
    },
    threeNumber() {
      if(!this.inputResult)
        return []

      const awards = this.inputResult.split('')
      if(awards.length >= 3) {
        return [`${awards[awards.length-3]}${awards[awards.length-2]}${awards[awards.length-1]}`]
      }else{
        return []
      }
    },
    fourNumberTode() {
      if(!this.fourNumber.length)
        return []

      const fourNumber = this.fourNumber[0]
      const awards = fourNumber.split('')

      return _.uniq([
        `${awards[0]}${awards[1]}${awards[2]}${awards[3]}`,
        `${awards[0]}${awards[1]}${awards[3]}${awards[2]}`,
        `${awards[0]}${awards[2]}${awards[1]}${awards[3]}`,
        `${awards[0]}${awards[2]}${awards[3]}${awards[1]}`,
        `${awards[0]}${awards[3]}${awards[2]}${awards[1]}`,
        `${awards[0]}${awards[3]}${awards[1]}${awards[2]}`,
        `${awards[1]}${awards[0]}${awards[2]}${awards[3]}`,
        `${awards[1]}${awards[0]}${awards[3]}${awards[2]}`,
        `${awards[1]}${awards[2]}${awards[0]}${awards[3]}`,
        `${awards[1]}${awards[2]}${awards[3]}${awards[0]}`,
        `${awards[1]}${awards[3]}${awards[2]}${awards[0]}`,
        `${awards[1]}${awards[3]}${awards[0]}${awards[2]}`,
        `${awards[2]}${awards[0]}${awards[1]}${awards[3]}`,
        `${awards[2]}${awards[0]}${awards[3]}${awards[1]}`,
        `${awards[2]}${awards[1]}${awards[0]}${awards[3]}`,
        `${awards[2]}${awards[1]}${awards[3]}${awards[0]}`,
        `${awards[2]}${awards[3]}${awards[1]}${awards[0]}`,
        `${awards[2]}${awards[3]}${awards[0]}${awards[1]}`,
        `${awards[3]}${awards[0]}${awards[1]}${awards[2]}`,
        `${awards[3]}${awards[0]}${awards[2]}${awards[1]}`,
        `${awards[3]}${awards[1]}${awards[0]}${awards[2]}`,
        `${awards[3]}${awards[1]}${awards[2]}${awards[0]}`,
        `${awards[3]}${awards[2]}${awards[1]}${awards[0]}`,
        `${awards[3]}${awards[2]}${awards[0]}${awards[1]}`
      ])
    },
    threeNumberTode() {
      if(!this.threeNumber.length)
        return []

      const threeNumber = this.threeNumber[0]
      const awards = threeNumber.split('')

      return _.uniq([
        `${awards[0]}${awards[1]}${awards[2]}`,
        `${awards[0]}${awards[2]}${awards[1]}`,
        `${awards[1]}${awards[0]}${awards[2]}`,
        `${awards[1]}${awards[2]}${awards[0]}`,
        `${awards[2]}${awards[1]}${awards[0]}`,
        `${awards[2]}${awards[0]}${awards[1]}`,
      ])
    },
    twoNumberPrefix() {
      if(!this.fourNumber.length)
        return []

      const fourNumber = this.fourNumber[0]
      const awards = fourNumber.split('')

      return [`${awards[0]}${awards[1]}`]
    },
    twoNumberSuffix() {
      if(!this.fourNumber.length)
        return []

      const fourNumber = this.fourNumber[0]
      const awards = fourNumber.split('')

      return [`${awards[2]}${awards[3]}`]
    },
    results() {
      return this.marketOpenBets.reduce((results, openBet)=>{
        results[openBet.code] = this[openBet.code]
        return results
      }, {})
    },
    processPrizes() {
      const cuurentStep = this.round?.resultStep ?? null
      let prevStep = null
      return this.marketOpenBets.map((openBet)=>{
        const isAvailable = ((cuurentStep==='ProcessingTickets' && prevStep===null) || cuurentStep===prevStep)
        prevStep = openBet.code
        return {
          isAvailable: isAvailable,
          ...openBet
        }
      })
    },
    lastProcessPrize() {
      return this.processPrizes[this.processPrizes.length - 1]?.code
    },
    canChangeResult() {
      return [
        'Completed',
        'ProcessingTickets',
        'fiveNumber',
        'fourNumber',
        'threeNumber',
        'fourNumberTode',
        'threeNumberTode',
        'twoNumberPrefix',
        'twoNumberSuffix',
        'loseItems',
        'WonTickets',
        'LoseTickets',
        'payWonMember',
        'findAgent',
        'payAgent'
      ].includes(this.resultStep)
    }
  },
  watch: {
    round(n, o) {
      this.inputResult = this.resultText
    }
  },
  methods: {
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveResults() {

      if(this.inputResult.length !== this.resultLength)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณากรอกผล ให้ถูกต้อง'
        })

      Swal.fire({
        title: 'ยืนยันบันทึกผล?',
        text: "กรุณาตรวจสอบผลให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return SetsService.saveSetsResults(this.round._id, {
            result: this.inputResult
          }).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    ProcessingTickets() {
      Swal.fire({
        title: 'เริ่มคำนวนผล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return SetsService.updateProcessingTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'บันทึกผลหวยไม่สำเร็จ',
              'error'
            )
          })
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    ProcessPrize(openBet) {
      Swal.fire({
        title: `คำนวนผล ${openBet.text}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return SetsService.updateProcessPrize(this.round._id, openBet.code).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'คำนวนผลหวยไม่สำเร็จ',
              'error'
            )
          })
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'คำนวนผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    LoseItems() {
      Swal.fire({
        title: `คำนวนไม่ถูกรางวัล`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return SetsService.updateLoseItems(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'คำนวนผลหวยไม่สำเร็จ',
              'error'
            )
          })
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'คำนวนผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    WonTickets() {
      Swal.fire({
        title: 'อัพเดตโพยถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return SetsService.updateWonTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'อัพเดตโพยถูกรางวัลไม่สำเร็จ',
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'อัพเดตโพยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    LoseTickets() {
      Swal.fire({
        title: 'อัพเดตโพยไม่ถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return SetsService.updateLoseTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'อัพเดตโพยไม่สำเร็จ',
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'อัพเดตโพยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    payWonMembers() {
      Swal.fire({
        title: 'จ่ายเงินสมาชิก?',
        text: 'จ่ายเงินสมาชิกที่ถูกรางวัล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.payWonMembers(this.round._id, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'จ่ายเงินสมาชิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'จ่ายเงินสมาชิกเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    findAgents() {
      Swal.fire({
        title: 'สรุปยอดแพ้ชนะของเอเย่นต์!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.findAgentWinLose(this.round._id, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'คำนวนยอดสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    payAgents() {
      Swal.fire({
        title: 'ปรับยอด!',
        text: "ปรับยอดแพ้ชนะบัญชีเอเย่นต์",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.payAgents(this.round._id, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'จ่ายเงินสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    closeTickets() {
      Swal.fire({
        title: 'จบการออกผล!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return SetsService.closeTickets(this.round._id, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'เสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    closeRound() {
      Swal.fire({
        title: 'ปิดงวดหวย!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.closeRound(this.round._id, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ปิดงวดเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    changeResult(step, text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.changeResult(this.roundId, step, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'ยกเลิกจ่ายเงินไม่สำเร็จ',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    changeResultTicket(text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.changeResultTicket(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    changeResultItem(text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return SetsService.changeResultItem(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              'ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    }
  },
  mounted() {
    this.inputResult = this.resultText
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 1px;
}
</style>
